<template>
  <v-card id="account-setting-card">
    <div id="instagram" style="padding: 30px">
      <h1>WhatsApp</h1>
      <v-app id="inspire">
        <v-card>
          <v-card-title>
            Notifications
            <v-col class="d-flex" cols="7" sm="4">
              <v-select
                v-model="selected"
                :items="notificationValues"
                label="Select messenger"
                item-text="name"
                item-value="code"
                @change="getNotificationList()"
                outlined
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <template>
            <v-chip
              v-for="itemss in chips"
              :key="itemss"
              color="pink"
              style="margin: 2px"
              @click="searchChips(itemss.key)"
            >
              {{ itemss.key }}
            </v-chip>
          </template>
          <v-data-table
            :loading="!myitems.length"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="myitems"
            :search="search"
            sort-field="durations"
          ></v-data-table>
        </v-card>
      </v-app>
    </div>
  </v-card>
</template>
<style scoped>
.pink {
  background-color: #880e4f !important;
}
.blue {
  background-color: blue !important;
}
.green {
  background-color: green !important;
}
</style>
<script>
import { doc, getDoc, query, getDocs, where, collection, orderBy, startAfter, limit } from 'firebase/firestore'
import { dbRef, instagramClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
export default {
  name: 'instagram',
  data() {
    return {
      notificationValues: [
        {
          name: 'WhatsApp',

          code: 'com.whatsapp',
        },
        {
          name: 'WhatsApp Business',
          code: 'com.whatsapp.w4b',
        },

        {
          name: 'Instagram',
          code: 'com.instagram.android',
        },
        {
          name: 'All',
          code: '',
        },
      ],

      search: '',
      selected: 'com.whatsapp',
      myitems: [],
      chips: [],
      lists: {},
      headers: [
        {
          text: 'Packages',
          align: 'start',
          sortable: false,
          value: 'packages',
        },
        { text: 'Title', value: 'title' },
        { text: 'Text', value: 'text' },
        { text: 'Time', value: 'time' },
      ],
      desserts: [
        {
          deviceID: 'Frozen Yogurt',
          durations: 159,
          name: 6.0,
          carbs: 24,
          number: 4.0,
          time: '1%',
        },
      ],
    }
  },
  mounted: function () {
    instagramClick()
    this.getNotificationList()
  },
  methods: {
    async searchChips(e) {
      console.log(e)
      this.search = e
    },
    async getChips(e) {
      let values = [...new Set(e.map(item => item.title))]
      this.chips.length = 0
      values.forEach(element => {
        let n = {
          key: element,
        }
        this.chips.push(n)
      })
    },
    async getNotificationList() {
      this.$store.commit('LOADER', true)
      let uid = getUID()

      const citiesRef = collection(dbRef, 'notification')

      let q
      if (this.selected == '') {
        q = query(citiesRef, where('uid', '==', uid), where('deviceId', '==', getCurrentDeviceSelected()))
      } else {
        q = query(
          citiesRef,
          where('packages', '==', this.selected),
          where('uid', '==', uid),
          where('deviceId', '==', getCurrentDeviceSelected()),
        )
      }

      getDocs(q)
        .then(res => {
          this.myitems.length = 0
          res.forEach(ress => {
            // doc.data() is never undefined for query doc snapshots
            console.log(ress.id, ' => ', ress.data())
            this.myitems.push(ress.data())
          })
          this.getChips(this.myitems)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
  },
}
</script>